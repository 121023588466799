<!--

Carousel
------------
Generic carousel with no defined card template.

Props
- elements (Object[]): array of element data,
- elementsPerPage (number): how many elements (cards) per page ? Ex : 2, 2.5, 3.75..
- elementsPerPageDesktop (number): same, but on desktop
- desktopBreakpoint (number): pixel breakpoint to desktop display, default is 640
- gap (number): margin in px between elements
- centered (boolean): cards will be centered, default is false
- drag (boolean): enable/disable dragging
- skeletonNbElements (number): number of skeleton elements to show
- skeletonCentered (boolean): if they are centered
- controls (boolean) : show previous/next arrows
- controlsTopOffset (number, 0-1) : percentage of element width to use as top margin for controls, default = 0.5
-->
<template>
  <div class="relative h-full w-full">
    <CarouselSkeleton
      v-if="!isInitialized"
      :nb-elements="skeletonNbElements"
      :centered="skeletonCentered" />
    <div
      ref="container"
      class="carousel relative h-full w-full max-w-full overflow-hidden">
      <div
        v-show="isInitialized"
        ref="inner"
        class="carousel-inner flex h-full"
        :style="{ width: `${innerWidth}px` }">
        <div
          v-for="(element, index) in elements"
          :key="index"
          ref="element"
          :style="{
            'margin-left': `${
              index == 0 ? (centered ? centeredOffset : gap) : gap
            }px`,
            width: `${elementWidth}px`
          }"
          class="flex flex-col"
          @click="goToPage(index)">
          <slot
            name="slide"
            v-bind="{ element, active: index === currentIndex }" />
        </div>
      </div>
    </div>

    <SquareButton
      v-if="controls && canGoPrevious && nbPages > 0"
      icon="arrow-drop-left-line"
      class="absolute left-0 hidden -translate-y-1/2 transform !rounded-full shadow-2xl dt:flex"
      :style="{ top: elementWidth * controlsTopOffset + 'px' }"
      :aria-label="$t('Slider.previous')"
      :dark="false"
      @click="goPrevious" />

    <SquareButton
      v-if="controls && canGoNext && nbPages > 0"
      icon="arrow-drop-right-line"
      class="absolute right-0 top-1/2 hidden -translate-y-1/2 translate-x-1/2 transform !rounded-full shadow-2xl dt:flex"
      :style="{
        right: elementWidth / 2 + 'px',
        top: elementWidth * controlsTopOffset + 'px'
      }"
      :aria-label="$t('Slider.next')"
      :dark="false"
      @click="goNext" />
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'

import gsap from 'gsap'

import Draggable from 'gsap/Draggable'

const props = withDefaults(
  defineProps<{
    elements: Object[]
    elementsPerPage: number
    elementsPerPageDesktop?: number
    desktopBreakpoint?: number
    gap?: number
    drag?: boolean
    centered?: boolean
    skeletonNbElements: number
    skeletonCentered?: boolean
    controls?: boolean
    controlsTopOffset?: number
  }>(),
  {
    elementsPerPageDesktop: 5,
    desktopBreakpoint: 640,
    centered: false,
    drag: true,
    skeletonCentered: false,
    controls: false,
    controlsTopOffset: 0.5
  }
)

// threshold to drag from a slide to another
const DRAG_SENSITIVITY = 0.1

const container = ref<HTMLInputElement | null>(null)
const inner = ref<HTMLInputElement | null>(null)

let elementsPerPage: number

const x = ref(0)
const currentIndex = ref(0)
const elementWidth = ref(0)
const nbPages = ref(0)
const containerWidth = ref(0)
const innerWidth = ref(0)
const page = computed(() =>
  Math.floor(currentIndex.value / props.elementsPerPageDesktop)
)
const isAtEnd = computed(
  () =>
    props.elements?.length - currentIndex.value < props.elementsPerPageDesktop
)

// compute left margin for the first card to center it
const centeredOffset = computed(() => {
  if (!props.centered) {
    return 0
  }

  return (containerWidth.value - elementWidth.value) / 2
})

onMounted(() => {
  gsap.registerPlugin(Draggable)

  init()
  // refresh carousel on page resize, using debounce
  window.addEventListener('resize', initThrottled)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', initThrottled)
})

const minX = ref(0)
const maxX = ref(0)

// for better UX, we allow an little extra drag when we are at the begining or end of the carousel
// i selected an arbitrary 'elementWidth.value / 3' value
const softEdgeLimit = computed(() => elementWidth.value / 3)

let instance: Draggable[]
const isInitialized = ref(false)
const init = () => {
  isInitialized.value = false

  if (instance) {
    goToPage(0)
  }

  elementsPerPage = useScreen().isMobile.value
    ? props.elementsPerPage
    : props.elementsPerPageDesktop

  // tablet hack, divide elementsPerPageDesktop per two
  if (useScreen().isTablet.value) {
    elementsPerPage = Math.round(props.elementsPerPageDesktop / 2)
  }

  containerWidth.value = container.value?.getBoundingClientRect().width || 0

  elementWidth.value =
    containerWidth.value / elementsPerPage -
    Math.max(Math.floor(elementsPerPage) * props.gap, 0) / elementsPerPage

  innerWidth.value =
    props.elements?.length * elementWidth.value +
    props.elements?.length * props.gap +
    centeredOffset.value * 2

  nbPages.value = Math.ceil(props.elements?.length / elementsPerPage)

  minX.value = -(
    innerWidth.value -
    (elementsPerPage * elementWidth.value +
      Math.floor(elementsPerPage) * props.gap) +
    props.gap +
    softEdgeLimit.value
  )

  maxX.value = softEdgeLimit.value

  instance?.[0]?.kill()

  if (!props.drag || nbPages.value <= 1) {
    isInitialized.value = true
    return
  }

  // init draggable action
  instance = Draggable.create(inner.value, {
    type: 'x',
    bounds: {
      minX: minX.value,
      maxX: maxX.value
    },
    onDrag: function () {},
    onDragEnd: function () {
      updatePaginationFromPosition(this.x, this.deltaX)

      x.value = Math.round(this.x)
    }
  })

  isInitialized.value = true
}

const initThrottled = _.throttle(init, 10)

const goToPage = (index: number) => {
  if (!props.drag || nbPages.value <= 1 || index < 0) return

  currentIndex.value = index
  x.value = Math.round(gsap.getProperty(inner.value, 'x') as number)

  gsap.to(inner.value, {
    duration: 0.4,
    // clamp translation between bounds
    x: Math.max(
      -elementWidth.value * index - props.gap * index,
      minX.value + softEdgeLimit.value
    )
  })
}

const canGoPrevious = computed(() => currentIndex.value > 0)
const canGoNext = computed(() => !isAtEnd.value)
const goPrevious = () => goToPage(currentIndex.value - 1)
const goNext = () => canGoNext && goToPage(currentIndex.value + 1)

defineExpose({
  goToPage,
  goPrevious,
  goNext,
  canGoPrevious,
  canGoNext,
  nbPages,
  elementWidth
})

const updatePaginationFromPosition = (x: number, deltaX: number) => {
  const position = -x / (elementWidth.value + props.gap)
  const decimal = position - Math.floor(position)

  let newIndex = 0
  if (deltaX < 0 && decimal > DRAG_SENSITIVITY) {
    newIndex = Math.ceil(position)
  } else if (deltaX > 0 && decimal < 1 - DRAG_SENSITIVITY) {
    newIndex = Math.floor(position)
  } else {
    newIndex = Math.round(position)
  }

  currentIndex.value = _.clamp(newIndex, 0, props.elements?.length - 1)

  goToPage(currentIndex.value)
}

const emit = defineEmits(['change'])

watch(currentIndex, () => {
  emit('change', currentIndex.value)
})
</script>
